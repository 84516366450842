<!--
 * @Descripttion: 竞价厅
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-27 16:08:41
-->
<template>
  <el-main
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
  >
		<el-row type="flex" justify="space-between" align="center">
			<el-col :span="8"><h1 class="mid-title">网上竞价厅</h1></el-col>
			<el-col :span="8" class="text-right">
				<el-button type="danger" @click="handleExist">退出竞价厅</el-button>
			</el-col>
		</el-row>
		<el-row type="flex" justify="space-between" align="center" class="mt">
			<el-col :span="12">
				<div>
					<span class="base-title">项目名称: {{ project.projectName }}</span>
          <el-button type="primary" v-if="commInfos.length != 0" @click="showCommInfo = true" style="margin-left: 20px">确认承诺书</el-button>

				</div>
        <div class="round " style="margin-top: 10px;">
          竞价总轮次：共{{ project.rounds.length }}轮
        </div>
        <div class="round ml">
          {{ roundsText }}
        </div>
			</el-col>
			<el-col :span="12" class="text-right right-abs">
				<h1 class="serviceTime" v-if="project.processStatus !='20'">当前服务器时间: {{ currentServiceTime }}</h1>
        <div class="right-time">
          <el-tag type="warning" class="mt" v-show="countDownOverTime">{{ countDownOverTime }}</el-tag>
        </div>
        <div class="right-time">
          <el-tag style="color:#FF6F00" class="mt" v-if="project.quotationMethod =='4' && quoteStatus =='1'">当前最高报价（元）：{{parseFloat(currentMaxQuote) > 0 ? currentMaxQuote : ''}}</el-tag>
        </div>
			</el-col>
		</el-row>
		<el-row :gutter="20" class="mt">
			<el-col :span="2" class="bw">采购单位</el-col>
			<el-col :span="6" class="desc">{{ project.bnStoreName }}</el-col>
			<el-col :span="2" class="bw">报价方式</el-col>

			<el-col
        :span="6"
        class="el-link el-link--primary"
        style="justify-content: flex-start"
			>
				<template v-if="project.quotationMethod == '1'">
					金额报价
				</template>
				<template v-else-if="project.quotationMethod == '2'">
					优惠率报价
				</template>
				<template v-else-if="project.quotationMethod == '3'">
					金额报价定制版
				</template>
        <template v-else-if="project.quotationMethod == '4'">
					反向竞拍
				</template>
        <template v-else-if="project.quotationMethod == '5'">
					定额报价
				</template>
        <template v-else-if="project.quotationMethod == '6'">
					金额定制人工费
				</template>
				<template v-else>
					其他报价方式
				</template>
      </el-col>

		</el-row>
		<el-row :gutter="20" class="mt-normal">
			<el-col :span="2" class="bw">项目编号</el-col>
			<el-col :span="6" class="desc">{{ project.projectNo }}</el-col>
			<el-col :span="2" class="bw">竞价总轮次</el-col>
			<el-col :span="6" class="desc">共 {{ project.biddRounds}} 轮</el-col>
		</el-row>
    <el-row :gutter="20" class="mt-normal" v-if="project.quotationMethod == '4'">
			<el-col :span="2" class="bw">竞价开始时间</el-col>
			<el-col :span="6" class="desc">{{ project.biddBeginDatetime }}</el-col>
			<el-col :span="2" class="bw">竞价结束时间</el-col>
			<el-col :span="6" class="desc">{{ project.biddEndDatetime}}</el-col>
		</el-row>
		<el-row class="mt-normal" v-else>
      <el-col :span="8" v-for="(item,index) in project.rounds" :key="index">
        <p class="mini-title">第 {{ item.roundsNo }} 轮竞价</p>
        <p><span class="tempT">竞价开始时间</span> {{item.biddBeginDatetime}}</p>
        <p><span class="tempT">竞价结束时间</span> {{item.biddEndDatetime}}</p>
      </el-col>
		</el-row>

    <!-- 条目信息: 只有在优惠率报价时显示  -->
    <div v-if="project.quotationMethod === '2'">
    <p class="mid-title mt">需求条目</p>
    <el-table :data="items" border stripe style="width: 100%" header-row-class-name="tableHeader">
      <el-table-column type="index" label="#"></el-table-column>
      <el-table-column v-for="col in columns"
        :prop="col.key"
        :key="col.key"
        :label="col.text"
        :min-width="col.minWidth">
      </el-table-column>
      <template v-if="project.quotationMethod =='2'">
				<el-table-column label="计量单位"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.uom? scope.row.uom : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="采购数量"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.purchaseQuantity? scope.row.purchaseQuantity : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="预算优惠率（%）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{ scope.row.itemBudget }}</span>
          </template>
        </el-table-column>
        <el-table-column label="预算暂定价（元）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{ scope.row.budgetTempPrice }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="人工费（元）"  min-width="8%">
          <template slot-scope="scope">
            {{ scope.row.laborCost }}
          </template>
        </el-table-column> -->
        <el-table-column label="安全文明施工费（元）"  min-width="12%">
          <template slot-scope="scope">
            <span>{{ scope.row.safeCivilizeCost }}</span>
          </template>
        </el-table-column>
        <el-table-column label="规格型号"  min-width="15%">
          <template slot-scope="scope">
            {{ scope.row.standards }}
          </template>
        </el-table-column>
        <el-table-column label="详细参数"  min-width="15%">
          <template slot-scope="scope">
            {{ scope.row.detailParam }}
          </template>
        </el-table-column>
        </template>
    </el-table>
    </div>

    <!-- 条目信息: 金额报价定制版时显示  -->
    <div v-else-if="project.quotationMethod === '3' || project.quotationMethod === '6'">
    <p class="mid-title mt">需求条目</p>
    <el-table :data="items" border stripe style="width: 100%" header-row-class-name="tableHeader">
      <el-table-column type="index" label="#"></el-table-column>
      <el-table-column v-for="col in columns"
        :prop="col.key"
        :key="col.key"
        :label="col.text"
        :min-width="col.minWidth">
      </el-table-column>
				<el-table-column label="计量单位"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.uom? scope.row.uom : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="采购数量"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.purchaseQuantity? scope.row.purchaseQuantity : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="预算控制价（元）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{ scope.row.itemBudget }}</span>
          </template>
        </el-table-column>
        <el-table-column label="最高限价（元）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{ scope.row.ceilingPrice }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="人工费（元）"  min-width="8%">
          <template slot-scope="scope">
            {{ scope.row.laborCost }}
          </template>
        </el-table-column> -->
        <el-table-column label="安全文明施工费（元）"  min-width="12%">
          <template slot-scope="scope">
            <span>{{ scope.row.safeCivilizeCost }}</span>
          </template>
        </el-table-column>
        <el-table-column label="规格型号"  min-width="15%">
          <template slot-scope="scope">
            {{ scope.row.standards }}
          </template>
        </el-table-column>
        <el-table-column label="详细参数"  min-width="15%">
          <template slot-scope="scope">
            {{ scope.row.detailParam }}
          </template>
        </el-table-column>
    </el-table>
    </div>

		<!-- 第一轮竞价 -->
		<div  v-for="round in project.rounds" :key="round.id">
      <el-row :gutter="20" class="mid-title mt">
        <el-col :span="11" v-if="project.quotationMethod !='4'">第{{ round.roundsNo }}轮竞价</el-col>
        <template v-if="project.quotationMethod == '5'">
        <el-col :span="5">预算价格合计（元）：{{ project.sumTotalPriceWithTax }}</el-col>
        <el-col :span="3">税率：{{ Number(round.items[0].taxRate) * 100 + '%' }}</el-col>
        <el-col :span="5" style="text-align: right;">
          <el-button type="success" size="medium" @click="download">下载模板</el-button>
          <el-button type="warning" size="medium">导入报价</el-button>
        </el-col>
        </template>
      </el-row>
			<!-- <p class="mid-title mt" v-if="project.quotationMethod !='4'">
        <span>第{{ round.roundsNo }}轮竞价</span>
        <span>预算价格合计（元）：{{ project.sumTotalPriceWithTax }}</span>
        <span></span>
      </p> -->
			<el-table :data="round.items" border stripe class="mt-normal" header-row-class-name="tableHeader">
				<el-table-column type="index" label="#"></el-table-column>
				<el-table-column
          v-for="col in columns"
          :prop="col.key"
          :key="col.key"
          :label="col.text"
          :min-width="col.minWidth"
				>
				</el-table-column>

        <template v-if="project.quotationMethod =='1'">
				<el-table-column label="规格型号"  min-width="10%">
          <template slot-scope="scope">
            <span>{{scope.row.standards? scope.row.standards : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="详细参数"  min-width="10%">
          <template slot-scope="scope">
            <span>{{scope.row.detailParam? scope.row.detailParam : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="计量单位"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.uom? scope.row.uom : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="采购数量"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.purchaseQuantity? scope.row.purchaseQuantity : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="预算金额（元）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{scope.row.itemBudget? scope.row.itemBudget : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="金额报价（元）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{scope.row.offerRecords.length>0? scope.row.offerRecords[0].price : '' }}</span>
          </template>
        </el-table-column>
        </template>

        <template v-else-if="project.quotationMethod =='2'">
				<el-table-column label="计量单位"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.uom? scope.row.uom : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="采购数量"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.purchaseQuantity? scope.row.purchaseQuantity : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="报价优惠率（%）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{scope.row.offerRecords.length>0? scope.row.offerRecords[0].price : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="报价总金额（元）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{ mOfferTotalPrice(scope.row) }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="人工费（元）"  min-width="8%">
          {{ currentLaborCost }}
        </el-table-column> -->
        <el-table-column label="安全文明施工费（元）"  min-width="12%">
          {{ currentSafeCivilizeCost }}
          <!-- <template slot-scope="scope">
            <span>{{ scope.row.offerRecords[0].price? scope.row.offerRecords[0].safeCivilizeCost :items[0].safeCivilizeCost }}</span>
          </template> -->
        </el-table-column>
        </template>

        <template v-else-if="project.quotationMethod =='3' || project.quotationMethod =='6'">
				<el-table-column label="计量单位"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.uom? scope.row.uom : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="采购数量"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.purchaseQuantity? scope.row.purchaseQuantity : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="金额报价（元）"  min-width="8%">
          <template slot-scope="scope">
            <span>{{scope.row.offerRecords.length>0? scope.row.offerRecords[0].price : '' }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="人工费（元）"  min-width="8%">
          <template slot-scope="scope">
            {{ project.quotationMethod =='3' ? scope.row.laborCost :  scope.row.offerRecords[0].price}}
          </template>
        </el-table-column> -->
        <el-table-column label="安全文明施工费（元）"  min-width="12%">
          <template slot-scope="scope">
            <span>{{ scope.row.safeCivilizeCost }}</span>
          </template>
        </el-table-column>
        </template>

        <template v-else-if="project.quotationMethod =='4'">
				<el-table-column label="计量单位"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.uom? scope.row.uom : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="采购数量"  min-width="6%">
          <template slot-scope="scope">
            <span>{{scope.row.purchaseQuantity? scope.row.purchaseQuantity : '' }}</span>
          </template>
        </el-table-column>
				<el-table-column label="预算金额（元）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{scope.row.itemBudget? scope.row.itemBudget : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="最低加价幅度（元）"  min-width="8%">
          <template slot-scope="scope">
            {{ scope.row.miniPriceIncreRange }}
          </template>
        </el-table-column>
				<el-table-column label="报价金额（元）"  min-width="10%">
          <template slot-scope="scope">
            <span>{{scope.row.offerRecords.length>0? scope.row.offerRecords[0].price : '' }}</span>
          </template>
        </el-table-column>

        </template>

        <template v-else-if="project.quotationMethod == '5'">
          <el-table-column
            :key="tt.key"
            :prop="tt.key"
            :label="tt.text"
            :min-width="tt.minWidth"
            v-for="tt in quotaColumns"
          />
        </template>

				<el-table-column label="操作" min-width="8%">
					<template #default="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="checkCommInfoEnable && quoteStatus==='1' && currentRoundsNo===round.roundsNo &&offerStatus==='1'"
              @click="onQuote(scope.row, round.id, round.roundsNo)"
            >
              {{ scope.row.offerRecords[0].price ? '修改报价' : '我要报价' }}
            </el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 附件上传 -->
		<el-row class="minH">
			<el-upload
			:action="uploadUrl"
			:on-preview="handlePreview"
			:on-remove="handleRemove"
			:before-remove="beforeRemove"
			:before-upload="beforeUpload"
			multiple
			:limit="5"
			:on-exceed="handleExceed"
			:on-success="handleSuccess"
			:on-error="handleError"
			:file-list="fileList"
			class="upload-list-vertical"
			>
				<el-button class="ttt" size="small" type="primary">上传竞价附件</el-button>
				<template #tip>
					<div class="el-upload__tip">单个附件不超过10M，附件数量不超过5个</div>
				</template>
			</el-upload>
		</el-row>
		<el-row class="minH">
			<el-col :span="24" class="upload-list-vertical">
				<el-button type="primary" class="el-button--small" @click="showNetDisk=true">添加网盘链接</el-button>
				<span class="el-upload__tip">附件过大，或者数量过多时，可以提供网盘链接</span>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="24">
				<ul class="el-upload-list el-upload-list--text">
					<li
					:key="index"
					v-for="(net,index) in netDisk"
					class="el-upload-list__item is-success tempLi">
						<p class="el-upload-list__item-name">
							{{net.ndName}}
						</p>
						<p class="el-upload-list__item-name">
							{{net.ndContent}}
						</p>
						<p class="el-upload-list__item-name">提取码：{{net.ndCode}}</p>
						<label class="el-upload-list__item-status-label">
							<i class="el-icon-upload-success el-icon-circle-check"></i>
						</label>
						<i class="el-icon-close" @click="delNetDisk(index)" ></i>
					</li>
				</ul>
			</el-col>
		</el-row>

    <!-- 我要报价-dialog -->
    <el-dialog title="我要报价" :visible.sync="showQuote" width="600px" top="70px" center :close-on-click-modal="false">
      <el-main>
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">标的名称：</p></el-col>
          <el-col :span="16">{{ tempItem.itemName }}</el-col>
        </el-row>
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">规格型号：</p></el-col>
          <el-col :span="16">
            <div>
              <p>{{ tempItem.standards }}</p>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">采购数量：</p></el-col>
          <el-col :span="16">{{
            tempItem.purchaseQuantity + tempItem.uom
          }}</el-col>
        </el-row>
        <div style="background-color: #F1F1F1;">
        <el-row :gutter="10" class="quote">
          <template v-if="project.quotationMethod =='1'">
						<el-col :span="8"><p class="desc">预算金额（元）：</p></el-col>
					</template>
					<template v-else-if="project.quotationMethod =='2'">
						<el-col :span="8"><p class="desc">预算优惠率（%）：</p></el-col>
					</template>
					<template v-else-if="project.quotationMethod =='3' || project.quotationMethod =='6'">
						<el-col :span="8"><p class="desc">预算控制价（元）：</p></el-col>
					</template>
					<template v-else>
						<el-col :span="8"><p class="desc">预算金额（元）：</p></el-col>
					</template>
					<el-col :span="16">{{ tempItem.itemBudget }}</el-col>
        </el-row>
        <!-- 优惠率报价显示字段 -->
        <template v-if="project.quotationMethod =='2'">
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">预算暂定价（元）：</p></el-col>
          <el-col :span="16">{{ tempItem.budgetTempPrice }}</el-col>
        </el-row>
        <!-- <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">人工费（元）：</p></el-col>
          <el-col :span="16">{{ autoLaborCost }}</el-col>
        </el-row> -->
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">安全文明施工费（元）：</p></el-col>
          <!-- <el-col :span="16">{{ tempItem.safeCivilizeCost }}</el-col> -->
          <el-col :span="16">{{ autoSafeCivilizeCost }}</el-col>
        </el-row>
        </template>
        <!-- 金额报价定制版显示字段 -->
        <template v-else-if="project.quotationMethod =='3' || project.quotationMethod =='6'">
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">最高限价（元）：</p></el-col>
          <el-col :span="16">{{ tempItem.ceilingPrice }}</el-col>
        </el-row>
        <!-- <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">人工费（元）：</p></el-col>
          <el-col :span="16">{{ tempItem.laborCost }}</el-col>
        </el-row> -->
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">安全文明施工费（元）：</p></el-col>
          <el-col :span="16">{{ tempItem.safeCivilizeCost }}</el-col>
        </el-row>
        </template>

        <!-- 反向竞拍 -->
        <template v-else-if="project.quotationMethod =='4' && parseFloat(currentMaxQuote) > 0">
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">当前最高报价（元）：</p></el-col>
          <el-col :span="16">{{ currentMaxQuote }}</el-col>
        </el-row>
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">最低加价幅度（元）：</p></el-col>
          <el-col :span="16">{{ tempItem.miniPriceIncreRange }}</el-col>
        </el-row>
        </template>

        </div>
        <el-row :gutter="10" class="quote" type="flex" align="middle">
          <template v-if="project.quotationMethod =='1'">
					<el-col :span="8"><p class="desc">报价金额（元）：</p></el-col>
					</template>
          <template v-else-if="project.quotationMethod =='2'">
					<el-col :span="8"><p class="desc">优惠率报价（%）：</p></el-col>
					</template>
          <template v-else-if="project.quotationMethod =='3'">
					<el-col :span="8"><p class="desc">报价金额（元）：</p></el-col>
					</template>
          <template v-else>
					<el-col :span="8"><p class="desc">报价金额（元）：</p></el-col>
					</template>

					<el-col :span="16">
            <el-input
              v-model="itemQuote"
              @input="onInput(tempItem)"
              type="number"
              size="normal"
              clearable
            ></el-input>
          </el-col>

        </el-row>
        <el-row :gutter="10" class="quote" v-if="project.quotationMethod =='6'">
          <el-col :span="8"><p class="desc">人工费（元）：</p></el-col>
          <el-col :span="16">
            <el-input v-model="itemQuote" disabled readonly size="normal"></el-input>
          </el-col>
        </el-row>
				<!-- 金额报价显示字段 -->
				<template v-if="project.quotationMethod =='1'">
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">单价（元）：</p></el-col>
          <el-col :span="16">{{ unitPrice }}</el-col>
        </el-row>
				</template>
        <template v-if="project.quotationMethod == '2'">
        <el-row :gutter="10" class="quote">
          <el-col :span="8"><p class="desc">报价总金额（元）：</p></el-col>
          <el-col :span="16">{{ offerTotalPrice }}</el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="el-tag text-center">报价总金额=预算暂定价×（1-优惠率报价）</el-col>
        </el-row>
        </template>
        <el-row class="tips-price" v-if="project.quotationMethod =='4'">
          <p>温馨提示：报价要求</p>
					<p>不能低于预算金额</p>
          <p>不能低于当前最高报价+最低加价幅度</p>
        </el-row>
        <el-row class="tips-price" v-else>
          <p>温馨提示：竞价排名规则</p>
          <p v-if="project.quotationMethod === '1'">金额报价越低，排名越靠前</p>
          <p v-else-if="project.quotationMethod == '2'">优惠率报价越高，排名越靠前</p>
					<p v-else-if="project.quotationMethod == '3'">金额报价越低，排名越靠前</p>
					<p v-else>金额报价越低，排名越靠前</p>
          <p>报价相同的，报价早的排名靠前</p>
        </el-row>
      </el-main>

      <span slot="footer">
        <el-button @click="showQuote = false" type="info">取消</el-button>
        <el-button type="success" @click="handleQuote">确定</el-button>
      </span>
    </el-dialog>

		<!-- 添加网盘链接 -- Dialog -->
		<el-dialog :visible.sync="showNetDisk" :close-on-click-modal="false" width="600px" center>
			<template #title>
				<h1 class="base-title">添加网盘链接</h1>
			</template>
			<el-form :model="netDiskForm" ref="netDiskForm" label-position="left" label-width="80px">
				<el-form-item label="名称" prop="ndName" :rules="[{required:true, message:'请输入名称',trigger:'blur'}]">
					<el-input v-model="netDiskForm.ndName"></el-input>
				</el-form-item>
				<el-form-item label="网址" prop="ndContent" :rules="[{required:true, message:'请输入有效的链接地址',trigger:'blur'}]">
					<el-input
					type="textarea"
					v-model="netDiskForm.ndContent"
					:autosize="{minRows:3, maxRows:6}"
					>
					</el-input>
				</el-form-item>
				<el-form-item label="提取码" prop="ndCode">
					<el-input v-model="netDiskForm.ndCode"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="info" size="medium" @click="showNetDisk=false">取消</el-button>
					<el-button type="primary" @click="addNetDisk('netDiskForm')" size="medium">保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

    <!-- 竞价承诺书-Dialog -->
		<el-dialog :visible.sync="showCommInfo" :close-on-click-modal="false" width="650px" center>
			<template #title>
				<p class="mid-title">竞价承诺</p>
			</template>
			<el-main>
				<p class="desc">本项目需要供应商履行以下承诺</p>
				<!-- 选中后， 会把 label 绑定的数据 存放到  selectedCommInfos 中 -->
				<el-checkbox-group v-model="selectedCommInfos" :disabled="checkCommInfoEnable">
					<el-checkbox v-for="item in commInfos" :key="item.id" :label="item.id" class="checkbox-comminfo">
							<a style="color:#0090FF" @click="handleView(item.commUrl)">{{item.commName}}</a>
					</el-checkbox>
				</el-checkbox-group>
			</el-main>

			<span slot="footer">
				<el-button v-if="!checkCommInfoEnable" @click="showCommInfo = false">取消</el-button>
				<el-button v-if="!checkCommInfoEnable" type="primary" @click="onConfirmComm">确认</el-button>
				<el-button v-if="checkCommInfoEnable" @click="showCommInfo = false">关闭</el-button>
			</span>
		</el-dialog>

    <!-- 反向竞拍项目 供应商报价排名 -->
		<el-dialog :visible.sync="showRanking" :show-close="false" :close-on-click-modal="false" width="800px" center>
			<template #title>
				<p class="mid-title">供应商竞价排名</p>
			</template>
			<el-main style="padding: 0">
				<el-table
          :data="rankingData"
          style="width: 100%"
          class="ranking-table"
          header-row-class-name="tableHeader"
        >
          <el-table-column
            v-for="column in rankingColumns"
            :key="column.key"
            :prop="column.key"
            :label="column.text"
            :min-width="column.minWidth"
            class-name="ranking-label"
          >
          </el-table-column>
        </el-table>
			</el-main>

			<span slot="footer">
				<el-button type="primary" @click="handleConfirmRanking">确认</el-button>
			</span>
		</el-dialog>
	</el-main>
</template>

<script>
import { dateFormat, countDown } from "@/utils/date";
import { add } from '@/utils/math'
import global from "@/common/global";
import { httpPost, httpGet, httpPut ,httpDelete} from "@/utils/http";
export default {
	name: "BidHall",
	data() {
		return {
			serviceTime: "",
			countDownOverTime: "",
      serviceTimeInterval:null,
      quoteStatusInterval: null,
      quoteStatus:'0',
			project: {},
			itemQuote: "",
			tempItem: {},
			currentRound:{},
      items:[],

			columns: [
        { key: "secondTypeName", text: "分类", minWidth: "10%" },
        { key: "itemName", text: "名称", minWidth: "15%" },
        // { key: "standards", text: "规格型号", minWidth: "10%" },
				// { key: "detailParam", text: "详细参数", minWidth: "10%" },
        // { key: "uom", text: "计量单位", minWidth: "6%" },
        // { key: "purchaseQuantity", text: "采购数量", minWidth: "6%" },
        // { key: "itemBudget", text: "预算优惠率（%）", minWidth: "10%" },
			],
      quotaColumns:[
        { key: "standards", text: "规格型号", minWidth: "10%" },
				{ key: "detailParam", text: "详细参数", minWidth: "10%" },
        { key: "uom", text: "计量单位", minWidth: "6%" },
        { key: "purchaseQuantity", text: "采购数量", minWidth: "6%" },
        { key: "postTaxPrice", text: "除税单价（元）", minWidth: "10%" },
        { key: "preTaxPrice", text: "含税单价（元）", minWidth: "10%" },
        { key: "amount", text: "金额（元）", minWidth: "10%" },
        { key: "totalPriceWithTax", text: "价税合计（元）", minWidth: "10%" },
      ],
			showQuote: false,
			urls: {
        serviceTime: "/ewbid/bidding/tbEwProjectSupplierOfferRecords/getServiceTime",
				quote: "/ewbid/bidding/tbEwProjectSupplierOfferRecords/savePrice",
				commInfo: "/ewbid/bidd/tbEwProjectCommInfo/selectByProjectInfo",
				saveCommInfo:"/ewbid/bidd/tbEwSupplierConfiirmComm/saveSupplierConfiirmComm",
				checkedCommInfos:"/ewbid/bidd/tbEwProjectCommInfo/selectCheckedCommInfo",
        items: '/ewbid/bidd/tbEwProjectItem/getProjectItem',
				deleteFile:'/ewbid/bidbase/tbsAttachment/customDelete',//删除附件
        getMaxQuote: '/ewbid/bidding/tbEwProjectSupplierOffer/getMaxQuote',
        /** 获取竞价供应商的竞价排名  */
        rankingUrl: '/ewbid/bidding/tbEwProjectSupplierOfferSum/toConfirmlist',
        /** 供应商确认竞价排名 */
        confirmUrl: '/ewbid/bidding/tbEwProjectSupplierOfferSum/doConfirm'
      },
			fileList:[],
			// 网盘链接
			showNetDisk:false,
			netDisk:[],
			netDiskForm: {
				name:'',
				ndName: '',
				ndCode: '',
			},
			uploadUrl:global.gateway+"/ewbid/bidbase/tbsAttachment/upload",
      roundsText: '',
      showCommInfo: false,
      selectedCommInfos:[],
			//项目结束状态
			projectEndStatus:"",
			commInfos:[],
			checkCommInfoEnable: false,
			overSize: false,
			currentRoundsNo: 0,
			offerStatus: '1',
      /** 当前最高报价 */
      currentMaxQuote: '',
      ws: '',
      loading: true,
      loadingText: '数据加载中..',
      /** 用于 websocket 传递及接收消息 */
      sessionId: '',
      showRanking: false,
      /** 供应商竞价排名 数据 */
      rankingColumns: [
        { key: "seqNum", text: "排序", minWidth: "10%" },
        { key: "supplierStoreName", text: "供应商名称", minWidth: "35%" },
        { key: "price", text: "报价（元）", minWidth: "20%" },
        { key: "offerDatetime", text: "最终报价时间", minWidth: "25%" },
      ],
      rankingData: [],
      count1: 0,
      count2: 0,
      overCount: false,
      overCount1: false,
      currentServiceTime: '',
      /** 优惠率项目当前人工费报价 **/
      currentLaborCost: '',
      /** 优惠率项目当前安全施工费报价 **/
      currentSafeCivilizeCost: '',

		};
	},
	created() {
		let _this = this;
		this.project = this.$ls.get("currentProject");
    if(this.project.quotationMethod == '2') {
      this.getCurrentPrice(this.project.id, this.$ls.get("storeInfo").id)
    }
    /** 反向竞拍项目且项目已结束，则查询供应商是否已确认 */
    if(this.project.processStatus == '20' && this.project.quotationMethod == '4') {
      this.getRankingData(this.project.id, this.project.supplierStoreId);
    }
    // if(this.project.quotationMethod =='1')
    //   this.columns[this.columns.length -1].text = '预算金额（元）'
		if(this.project.projectSupplierMap.offerStatus){
			this.offerStatus = this.project.projectSupplierMap.offerStatus;
		}else{
			this.offerStatus = '1';
		}
    this.getServiceTime()

    this.getProjectItem()
    this.getMaxQuote()
		//处理上传的url将邀请竞价供应商关联表的id作为url参数
		if(this.project.projectSupplierMap){
			this.uploadUrl = this.uploadUrl+"?projectId="+this.project.projectSupplierMap.id
			//查询附件
			let url = global.gateway+"/ewbid/bidbase/tbsAttachment/getAttachmentListWithBusinesskey"
			httpGet(url,{
				businesskey:this.project.projectSupplierMap.id
			}).then(res=>{
				if(res.status=="200"&&res.data.retCode=="0"){
					//列表
					if(res.data.attachments){
						for(let ii=0;ii<res.data.attachments.length;ii++){
							let ff = {}
							ff.id = res.data.attachments[ii].id
							ff.name = res.data.attachments[ii].attachmenttitle + "." + res.data.attachments[ii].extend
							ff.url = res.data.attachments[ii].url
							//
							_this.fileList.push(ff)
						}
					}
				}
			}).catch(err=>{
				alert(JSON.stringify(err))
			})
			//查询网盘附件
			url = global.gateway+"/ewbid/bidding/tbEwProjectSupplierOfferRecords/getCommsWithBusinesskey"
			httpGet(url,{
				businesskey:this.project.projectSupplierMap.id
			}).then(res=>{
				if(res.status=="200"&&res.data.retCode=="0"){
					if(res.data.comms){
						for(let ii=0;ii<res.data.comms.length;ii++){
							let co = {}
							co.id = res.data.comms[ii].id
							co.ndName = res.data.comms[ii].ndName
							co.ndContent = res.data.comms[ii].ndContent
							co.ndCode = res.data.comms[ii].ndCode
							//
							this.netDisk.push(co)
						}
					}
				}
			}).catch(err=>{
			alert(JSON.stringify(err))
			})
		}

		//查询当前项目承诺书
		this.selectCommInfos()
	},

  mounted() {
    if(this.project.quotationMethod == '4') {
      let storeId = this.$ls.get("storeInfo").id
      let url = process.env.VUE_APP_BASE_URL.replace("https://","wss://").replace("http://","ws://") + `/ewbid/websocket/${storeId}/${this.project.id}`
      this.ws =  new WebSocket(url)
      this.ws.onopen = this.wsOnOpen;
      this.ws.onclose = this.wsOnClose;
      this.ws.onerror = this.wsOnError;
      this.ws.onmessage = this.wsOnMessage;
    }
  },
	//释放
	destroyed() {
    // clearInterval(this.quoteStatusInterval)
    this.overCount = true
    this.overCount1 = true
    if(this.project.quotationMethod == '4')
      this.ws.close()
	},

  computed: {

    
    /**
     * @Description: 优惠率报价自动计算人工费，并保留两位小数
     * @Version: 1.0
     * @Autor: Niklaus
     * @Date: 2022-11-11 17:36:23
     * @LastEditors: Niklaus
     * @LastEditTime: 2022-11-11 17:36:23
     */
    autoLaborCost() {
      if(this.items.length == 0)
        return 0;
      else if(!this.itemQuote)
        return this.items[0].laborCost
      else {
        let result = Math.round(this.items[0].laborCost*100 * (100- Number(this.itemQuote)) / 100) / 100
        let str = result.toString().split(".")
        return str.length == 1 ? result + '.00' : str[1].length ==1 ? result + '0' : result
      }
    },

    autoSafeCivilizeCost() {
      if(this.items.length == 0)
        return 0;
      else if(!this.itemQuote)
        return this.items[0].safeCivilizeCost
      else {
        let result = Math.round(this.items[0].safeCivilizeCost*100 * (100- Number(this.itemQuote)) / 100) / 100
        let str = result.toString().split(".")
        return str.length == 1 ? result + '.00' : str[1].length ==1 ? result + '0' : result
      }
    },

    offerTotalPrice(){
      if(!this.itemQuote)
        return 0
      let result = Math.round(this.tempItem.budgetTempPrice*100 * (100- Number(this.itemQuote)) / 100) / 100
      let str = result.toString().split(".")
      return str.length == 1 ? result + '.00' : str[1].length ==1 ? result + '0' : result
    },
    unitPrice() {
      if(!this.itemQuote)
        return 0
      let str = this.tempItem.purchaseQuantity.toString().split(".")
      let exp = str[1]? str[1].length : 0
      let pow = Math.pow(10, exp)
      let result = Math.round( this.itemQuote * 100 * pow / (this.tempItem.purchaseQuantity * pow )) / 100
      let temp = result.toString().split(".")
      return temp.length == 1 ? result + '.00' : temp[1].length ==1 ? result + '0' : result
    }

  },

  beforeDestroy() {
    if(this.project.quotationMethod == '4')  {
      httpPost(global.gateway + '/ewbid/bidding/tbEwProjectSupplierOfferRecords/close/' + this.sessionId, {sessionId:this.sessionId}).then(res => {
        console.log(res)
      })
    }

  },

	methods: {
    /**
     * @Description: 优惠率项目查询当前报价
     * @Version: 1.0
     * @Autor: Niklaus
     * @Date: 2022-11-12 11:13:57
     * @LastEditors: Niklaus
     * @LastEditTime: Do not edit
     */
    getCurrentPrice(id, supplierStoreId) {
      console.log(id, supplierStoreId);
      httpGet(global.gateway + '/ewbid/bidding/tbEwProjectSupplierOfferRecords/list', 
        {projectId:id, supplierStoreId, delFlag: '0'}).then(({data}) => {
          if(data.success && data.result.total > 0) {
            if(data.result.records[0].price) {
              this.currentLaborCost =  data.result.records[0].laborCost
              this.currentSafeCivilizeCost = data.result.records[0].safeCivilizeCost
            }else {
              this.currentLaborCost = this.project.rounds[0].items[0].laborCost
              this.currentSafeCivilizeCost = this.project.rounds[0].items[0].safeCivilizeCost
            }
          }else {
            this.currentLaborCost = this.project.rounds[0].items[0].laborCost
            this.currentSafeCivilizeCost = this.project.rounds[0].items[0].safeCivilizeCost
          }
        })
    },    

    /**
     * @Description: 查询项目条目数据
     * @Author: Niklaus
     * @Date: 2021-09-16 19:51:28
     */
    getProjectItem(){
      httpGet(global.gateway + this.urls.items,{projectId: this.project.id}).then( ({data}) => {
        if(data.success) {
          this.loading =  false
          this.items = data.result
        }
        else
          this.$message.error(data.message)
      })
    },

    /**
     * @Description: 获取服务器时间，并定时更新时间
     * @Author: Niklaus
     * @Date: 2021-09-16 16:46:50
     */
    getServiceTime(){
      httpGet(global.gateway + this.urls.serviceTime).then( res => {
        this.count1 = 0
        this.count2 = 0
        this.overCount1 = false
        this.overCount = false
        this.serviceTime = res.data.result
        this.projectQuoteState()
        this.updateServiceTime()
        // setTimeout(this.updateServiceTime, 700);
      })
    },

    updateServiceTime() {
      this.count1++;
      let t1 =  new Date().getTime()
      let serviceTime = new Date(this.serviceTime)
      serviceTime.setSeconds(serviceTime.getSeconds() + this.count1)
      this.currentServiceTime = dateFormat(serviceTime)
      let t2 = new Date().getTime()
      let nextTime = 1000 - (t2 - t1)
      if(!this.overCount1) {
        setTimeout(() => {
          this.serviceTimeInterval = this.updateServiceTime()
        }, nextTime);
      }

    },

    /**
     * @Description: 根据轮次信息，更新显示的文字及 倒计时
     * @Author: Niklaus
     * @Date: 2021-09-16 16:48:28
     */
    projectQuoteState(){
      setTimeout(() => {
        this.quoteStatusInterval = this.newCountDown()
      }, 1000);
    },
		/**
		* @Description: 监听报价数字
		* @Author: Niklaus
		* @Date: 2021-09-05 21:12:23
		*/
		onInput(item){
			let itemBudget = item.itemBudget;
			let ceilingPrice = item.ceilingPrice;
			let temp = this.itemQuote
      if(!temp) return
      if(this.itemQuote < 0) {
        this.itemQuote = 0
        this.$message.error('输入值最小为 0')
      }else if(this.project.quotationMethod === '1' && parseFloat(this.itemQuote) > parseFloat(itemBudget)) {
        this.itemQuote = itemBudget
        this.$message.error('不可超过预算金额')
      }else if(this.project.quotationMethod =='2' && this.itemQuote > 100){
        this.itemQuote = 100
        this.$message.error('输入值最大为 100')
      }else if((this.project.quotationMethod === '3' || this.project.quotationMethod == '6') && parseFloat(this.itemQuote) > parseFloat(ceilingPrice)) {
        this.itemQuote = ceilingPrice
        this.$message.error('不可超过最高限价')
      }else{
        let tt = this.itemQuote.split(".");
        if(tt.length>1 && tt[1].length>2) {
          this.$message.error('最多输入两位小数')
          this.itemQuote = tt[0] +"." + tt[1].substring(0,2)
        }
      }
		},
		/**
		* @Description: 提交报价，并将报价数据写入缓存
		* @Author: Niklaus
		* @Date: 2021-09-03 15:24:41
		*/
		handleQuote() {
      if(this.project.processStatus == '20') {
        this.$message.error("项目已结束，不允许报价！")
				return;
      }
			if (!this.itemQuote|| parseFloat(this.itemQuote) < 0) {
				this.$message.error("请输入大于0的金额")
				return;
			}
			if(this.project.quotationMethod =='2' && this.tempItem.itemBudget&&parseFloat(this.itemQuote)<parseFloat(this.tempItem.itemBudget)){
				this.$message.error("请输入大于等于预算优惠率的数字！")
				return;
			}
      if(this.project.quotationMethod =='4') {
        let msg = '', num  = 0
        if(this.currentMaxQuote !='' && this.currentMaxQuote != 0) {
          msg =  '不能低于当前最高报价+最低加价幅度:'
          num = add(this.currentMaxQuote, this.tempItem.miniPriceIncreRange)
        }else {
          msg =  '不能低于当前预算+最低加价幅度:' ;
          num = add(this.tempItem.itemBudget, this.tempItem.miniPriceIncreRange)
        }
        if(parseFloat(this.itemQuote)<parseFloat(num)) {
          this.$message.error(msg + num)
          return;
        }
      }

      this.loading = true
      this.loadingText = '提交报价中...'
			let data = {
				projectId: this.project.id,
				supplierStoreId: this.project.supplierStoreId,
				itemId: this.tempItem.id,
				roundsId: this.currentRound.id,
				roundsNo: this.currentRound.roundsNo,
				price: this.itemQuote,
			};
      if(localStorage.getItem('corp_bid_customer')) {
        data.createBy =  JSON.parse(localStorage.getItem('corp_bid_customer')).id
      }
			httpPost(global.gateway + this.urls.quote, data).then((res) => {
				if (res.data.success) {
          console.log(res.data);
          
          this.loading = false
					this.$message({ type: "success", message: "报价成功！" });
          this.showQuote = false;
          if(this.project.quotationMethod == '2') {
            this.currentLaborCost = res.data.result.laborCost
            this.currentSafeCivilizeCost = res.data.result.safeCivilizeCost
          }
					this.project.rounds.forEach( round => {
						if(round.id === this.currentRound.id) {
							round.items.forEach(item => {
								if(item.id == this.tempItem.id) {
									/**找到本次报价的条目后，更新缓存中的报价数据,offerRecords中只保留报价最新的一次数据 */
									item.offerRecords.length == 0 ? item.offerRecords.push(res.data.result) : item.offerRecords.splice(0,1,res.data.result)
								}
							});
						}
					})
					this.$ls.set("currentProject", this.project);
				} else {
          this.loading = false
					this.$message({ type: "error", message: res.data.message,duration:5000 });
					if(res.data.message.indexOf('当前轮次不允许报价')>1){
						this.offerStatus = '0';
					}
				}
			}).catch((error) => {
				this.$message.error("报价失败");
			});


		},
		/**
		* @Description: 报价前的数据处理
		* @Author: Niklaus
		* @Date: 2021-09-03 15:47:23
		* @param {*} row      当前条目
		* @param {*} roundId  当前轮次ID
		* @param {*} roundsNo 当前轮次编号
		*/
		onQuote(row,roundId, roundsNo) {
			this.currentRound.id = roundId
			this.currentRound.roundsNo = roundsNo
			this.showQuote = true;
			this.tempItem = row;
      if(this.project.quotationMethod == '4') {
        if(parseFloat(this.currentMaxQuote) <= 0 ) {
          this.itemQuote =add(this.tempItem.itemBudget,this.tempItem.miniPriceIncreRange)
        }else {
          this.itemQuote = add(this.currentMaxQuote,this.tempItem.miniPriceIncreRange)
        }
      }
      else
        this.itemQuote = row.offerRecords[0]? row.offerRecords[0].price: '';
		},
		//
		handleRemove(file, fileList) {
      if(file.id){
        let url = global.gateway+this.urls.deleteFile;
        let params={
          id:file.id,
          businesskey: this.project.projectSupplierMap.id
        }
        httpDelete(url,params).then(res=>{
          if(res.data.retCode=='0'){
            //删除成功
          }else{
            this.$message.error('删除失败！');
          }
        })
      }
		},
		handlePreview(file) {
			console.log(file);
		},
		handleExceed(files, fileList) {
			this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
		},
		beforeRemove(file, fileList) {
     let confirmRemove = false;
     //如果超过文件限制大小，不提示，直接删除
     if(this.overSize){
        confirmRemove = true;
      }else{
        confirmRemove = this.$confirm(`确定移除 ${ file.name }？`)
      }
      this.overSize = false;
      return confirmRemove
		},
		//上传前确定文件大小10M
		beforeUpload(file){
      const isLt1M = (file.size/1024/1024 < 10);
      if(!isLt1M){
        this.overSize = true;
        this.$message.error('上传文件大小不能超过 10MB!');
        return false;
      }
      this.overSize = false;
      return isLt1M
		},
		//上传成功后
		handleSuccess(res,file,fileList){
			let ret = res
			if(ret.retCode=="0"){
				let attachmentId = ret.attachmentId
				//复写id
				file.id = attachmentId
				//
				this.$message.info("上传成功")
			}else{
				this.$message.error("上传失败！")
				return
			}
		},
		handleError(err,file,fileList){
			alert(JSON.stringify(err))
		},
		/**
		* @Description: 添加网盘链接
		* @Author: Niklaus
		* @Date: 2021-08-25 14:52:49
		*/
		addNetDisk(formName){
			this.$refs[formName].validate( valid =>{
				if(valid) {
					this.netDisk.push({...this.netDiskForm})
					this.showNetDisk=false
					this.netDiskForm =  {
						ndName: '',
						ndContent:'',
						ndCode: '',
					}
					//
					let ii = this.netDisk.length - 1;
					let url = global.gateway+"/ewbid/bidding/tbEwProjectSupplierOfferRecords/insertComm";
					httpPost(url,{
						businesskey:this.project.projectSupplierMap.id,
						ndName:this.netDisk[ii].ndName,
						ndContent:this.netDisk[ii].ndContent,
						ndCode:this.netDisk[ii].ndCode
					}).then(res=>{
						if(res.status=="200"&&res.data.retCode=="0"){
							if(res.data.id){
								this.netDisk[ii].id = res.data.id
							}
							//提交
							this.$message({
								message: '已成功添加',
								type: 'success'
							})
						}
					}).catch(err=>{
						alert(JSON.stringify(err))
					})
				}else {
					this.$message('校验未通过');
				}
			})
		},
		/**
		* @Description: 页面删除网盘链接
		* @Author: Niklaus
		* @Date: 2021-08-25 15:58:08
		* @param {*} index
		*/
		delNetDisk(index) {
			//获取id
			if(this.netDisk[index].id){
				let id = this.netDisk[index].id
				let url = global.gateway+"/ewbid/bidding/tbEwProjectSupplierOfferRecords/deleteComm"
				httpGet(url,{
					id:id
				}).then(res=>{
					if(res.status=="200"){
						console.log(JSON.stringify(res.data))
					}
				}).catch(err=>{
					alert(JSON.stringify(err))
				})
			}
			this.netDisk.splice(index,1)
		},
		//查询承诺书
		selectCommInfos(){
			let params={}
			params.projectId = this.project.id;
			httpGet(global.gateway+this.urls.commInfo,params).then(res=>{
				if(res.data.success){
					this.commInfos = res.data.result;
					//查询已选的承诺书
					this.selectCheckedCommInfos();
				}else{
					this.$message.error('查询项目承诺书失败!');
				}
			}).catch(err=>{this.$message.error('查询项目承诺书失败!');});
		},
		//查询已选择的承诺书
		selectCheckedCommInfos(){
			let params={}
			params.projectId = this.project.id;
      params.supplierStoreId = this.$ls.get("storeInfo").id;

			httpGet(global.gateway+this.urls.checkedCommInfos,params).then(res=>{
				if(res.data.success){
					let checkedCommInfos = res.data.result;
					this.commInfos.forEach(item1=>{
						checkedCommInfos.forEach(item2=>{
							if(item1.id==item2.id){
								this.selectedCommInfos.push(item1.id);
							}
						});
					});
					if(checkedCommInfos.length==this.commInfos.length){
						this.checkCommInfoEnable = true;
						this.showCommInfo = false;
					}else{
						this.checkCommInfoEnable = false;
						this.showCommInfo = true;
					}

				}else{
					this.$message.error('查询项目承诺书失败!');
				}
			}).catch(err=>{this.$message.error('查询项目承诺书失败!');});
		},
    //查看链接
    handleView(url){
      if(url){
        window.open(url);
      }
    },
		//选定 承诺书
		onConfirmComm(){
			this.showCommInfo = false;
			if(this.selectedCommInfos.length==this.commInfos.length){
				this.checkCommInfoEnable = true
			}else{
				this.checkCommInfoEnable = false
			}
			let dataObj = {};
			dataObj.projectId = this.project.id;
			dataObj.projectNo = this.project.projectNo;
      dataObj.supplierStoreId =this.$ls.get("storeInfo").id;
      dataObj.supplierStoreName = this.$ls.get("storeInfo").storeName;
      dataObj.createBy = this.$ls.get("userInfo").id
			dataObj.checkAllCommInfo = this.checkCommInfoEnable;
			let list = [];
			this.selectedCommInfos.forEach(item1=>{
				this.commInfos.forEach(item2=>{
					if(item1==item2.id ){
						list.push(item2);
					}
				});
			});
			dataObj.list = list;
			httpPost(global.gateway+this.urls.saveCommInfo,dataObj).then(res=>{
				if(res.data.success){
					this.$message.success('保存成功!');
				}else{
					this.$message.error('保存失败!');
				}
			}).catch(err=>{this.$message.error('保存失败!')});
		},
		//计算优惠率报价的-报价总金额
    mOfferTotalPrice(item){
      if(item.offerRecords[0]&&item.offerRecords[0].price&&item.budgetTempPrice){
				let result = Math.round(item.budgetTempPrice*100 * (100- Number(item.offerRecords[0].price)) / 100) / 100
				let str = result.toString().split(".")
				return str.length == 1 ? result + '.00' : str[1].length ==1 ? result + '0' : result
			}else{
				return ''
			}
		},

    newCountDown() {
      /** 项目终止 */
      if(this.project.processStatus == '20') {
        this.overCount = true
        this.overCount1 =  true
        clearTimeout(this.serviceTimeInterval)
        clearTimeout(this.quoteStatusInterval)
        this.quoteStatus = '2'
        this.roundsText = '项目已结束'
        this.items.forEach(item => {
          if(item.processDetailStatus == '22') {
            this.roundsText = '项目已终止'
          }
        })
        return
      }
      if(this.count2 == 60) {
        this.overCount1 = true
        this.overCount = true
        clearTimeout(this.serviceTimeInterval)
        clearTimeout(this.quoteStatusInterval)
        this.getServiceTime()
        return
      }
      let t1 = new Date().getTime()
      ++this.count2
      let endTime = new Date(this.project.biddEndDatetime).getTime()
      let currentServiceTime = new Date(this.serviceTime).getTime() + this.count2 * 1000
      if(new Date(this.project.biddBeginDatetime).getTime() > currentServiceTime) {
        this.quoteStatus = '0'
        this.roundsText = '报价未开始'
        this.countDownOverTime ="距报价开始时间：" + countDown(new Date(currentServiceTime), new Date(this.project.biddBeginDatetime))
      }else if(endTime < currentServiceTime) {
        this.quoteStatus = '2'
        this.roundsText = '报价已结束'
        this.countDownOverTime = ""
        this.overCount = true
        if(this.project.quotationMethod == '4')
          this.getRankingData(this.project.id, this.project.supplierStoreId)
      }
      this.currentRoundsNo = 0;
      for (let i = 0; i < this.project.rounds.length; i++) {
        let round = this.project.rounds[i];
        if(currentServiceTime >= new Date(round.biddBeginDatetime).getTime() && currentServiceTime <= endTime) {
          this.roundsText = `当前轮次：第${i+1}轮进行中`
          if(this.project.quotationMethod == '4')
            this.countDownOverTime = `距竞价结束时间：` + countDown(new Date(currentServiceTime), new Date(round.biddEndDatetime))
          else
            this.countDownOverTime = `距第${i+1}轮结束：` + countDown(new Date(currentServiceTime), new Date(round.biddEndDatetime))
          this.quoteStatus = '1'
          this.currentRoundsNo = (i+1);
          break
        } else if(currentServiceTime < new Date(round.biddBeginDatetime).getTime()) {
          this.roundsText = `当前轮次：第${i+1}轮未开始`
          if(this.project.quotationMethod == '4')
            this.countDownOverTime = `距报价开始：` + countDown(new Date(currentServiceTime), new Date(round.biddBeginDatetime))
          else
            this.countDownOverTime = `距第${i+1}轮开始：` + countDown(new Date(currentServiceTime), new Date(round.biddBeginDatetime))

          this.quoteStatus = '0'
          break
        }
      }
      if(!this.overCount) {
        let t2 = new Date().getTime()
        let nextTime = 1000 - (t2 - t1)
        if(nextTime < 0) nextTime = 0
        setTimeout(() => {
          this.newCountDown()
        }, nextTime);
      }
    },

    /**
     * @Description: 推出竞价厅，并关闭webscoket
     * @Author: Niklaus
     * @Return:
     * @Date: 2022-05-22 12:13:42
     */
    handleExist() {
      this.$router.push('/onGoing')
      if(this.project.quotationMethod == '4') {
        httpPost(global.gateway + '/ewbid/bidding/tbEwProjectSupplierOfferRecords/close/' + this.sessionId, {sessionId:this.sessionId}).then(res => {
          // console.log(res)
        })
      }
    },

    /**
     * @Description:  获取项目的最高报价
     * @Author: Niklaus
     * @Date: 2022-05-21 09:25:00
     */
    getMaxQuote(){
      httpGet(global.gateway + this.urls.getMaxQuote, {projectId:this.project.id}).then(res => {
        if(res.data.success) {
          this.currentMaxQuote = res.data.message
        }
      })
    },

    /**
     * @Description: 获取供应商的竞价排名
     * @Author: Niklaus
     * @Date: 2022-05-22 12:18:06
     * @param projectId  项目ID
     * @param supplierStoreId 供应商Id
     */
    getRankingData(projectId, supplierStoreId){
      httpGet(global.gateway + this.urls.rankingUrl,{projectId,supplierStoreId}).then(res => {
        if(res.data.success) {
          this.rankingData = res.data.result
          if(this.rankingData.length > 0) {
            this.rankingData.forEach(item => {
              if(!item.price)
                item.price = '未报价'
            })
            this.showRanking = true
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },

    /**
     * @Description: 供应商确认竞价排名
     * @Author: Niklaus
     * @Return:
     * @Date: 2022-05-22 13:31:50
     */
    handleConfirmRanking(){
      let data = {projectId: this.project.id, supplierStoreId: this.project.supplierStoreId}
      httpPut(global.gateway+ this.urls.confirmUrl, data).then(res => {
        if(res.data.success) {
          this.showRanking = false
          this.$message.success('确认完成')
        }
      })
    },

    download(){
      window.location.href=global.gateway + "/ewbid/bidding/tbEwProjectSupplierOffer/quoteTemplate?projectId=" + this.project.id
    },

     /**
     * @Description:  websocket 相关监听方法
     * @Author: Niklaus
     * @Date: 2021-07-07 10:02:42
     */
    wsOnOpen(data){
      console.log("----- Front: ws open -----" );
    },

    wsOnMessage(msg){
      if(msg.data) {
        let res = JSON.parse(msg.data)
        if(res.SESSION_ID) {
          this.sessionId = res.SESSION_ID
        }else if(res.type == '1') { /** 最高报价 */
          this.currentMaxQuote = res.MAX_QUOTE
          let storeId = this.$ls.get("storeInfo").id
          if(storeId == res.orgId) {
            this.project.rounds.forEach( round => {
              round.items.forEach(item => {
                /**找到本次报价的条目后，更新缓存中的报价数据,offerRecords中只保留报价最新的一次数据 */
                item.offerRecords.length == 0 ? item.offerRecords.push({price: res.MAX_QUOTE}) : item.offerRecords[0].price = res.MAX_QUOTE
              });
            })
            this.$ls.set("currentProject", this.project);
          }

        }else if(res.type == '2') { /** 项目终止 **/
        if(this.project.id == res.projectId) {
          this.quoteStatus = '2'
          this.roundsText = '项目已被终止'
          this.countDownOverTime = ""
          this.overCount = true
          this.overCount1 = true
          this.project.processStatus = res.processStatus
          this.items.forEach(item => {
            item.processDetailStatus =  res.processDetailStatus
          });
        }

        }
      }
    },

    wsOnError(){
      console.error("----- Front: ws error -----" );
      this.$toast.fail("Font: websockt connection error")
    },

    wsOnClose(){
      console.log("----- Front: ws close -----" );
    },

	}
}
</script>

<style lang="scss" scoped>
.serviceTime {
  color: #ed5565;
  font-size: 22px;
  line-height: 30px;
}
.serviceTime + .mt {
  font-size: 24px;
  padding: 8px 20px;
  border-radius: 10px;
  height: auto;
}
.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.quote .el-col {
  padding: 7.5px 0;
}
.minH {
  margin-top: 30px;
  padding-bottom: 20px;
}

.tips-price {
  text-align: center;
  padding: 20px;
  background-color: #FAFAFA;
  margin-top: 15px;
  p {
    line-height: 20px;
    &:first-child {
      color: #FF3B50;
    }
  }
}
.round {
  display: inline-block;
  background-color: #EBFEFB;
  border: 1px solid #00A893;
  color: #00CCB2;
  padding: 10px 18px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 22px;
}
.checkbox-comminfo {
  display: block; padding: 10px 0;
}
.tempT {
  display: inline-block;
  font-weight: bold;
  margin-right: 15px;
  margin-top: 15px;
}
.desc {
  padding-left: 15px;
}
.right-abs {
  position: absolute;
  right: 0;
}
.right-time .el-tag {
    font-size: 24px;
    padding: 8px 20px;
    border-radius: 10px;
    height: auto;
}
.el-table--enable-row-transition .el-table__body td.ranking-label {
  border-bottom: none;
}


</style>